.resume-wrapper {
  text-align: center;
  padding-top: 120px;
  padding-bottom: 120px;
}

.resume-bg {
  background: rgb(131, 173, 255);
  background: linear-gradient(
    42deg,
    rgba(131, 173, 255, 1) 0%,
    rgba(36, 93, 204, 1) 44%,
    rgba(33, 42, 170, 1) 100%
  );
  background-attachment: fixed;
}

.resume-text {
  color: white;
  text-align: center;
}

.work-info {
  padding: 20px;
}

.experience-info {
  padding-bottom: 60px;
}

.padding-top {
  padding-top: 30px;
}

li {
  text-align: left;
  /* padding: 5px; */
}

.accordion {
  border: 1px gray solid;
  /* margin: 50px 0; */
}

.accordion-header {
  border-radius: 0 !important;
  cursor: pointer;
  transition: 200ms;
  background-color: white !important;
  color: black;
}

.accordion-header:hover {
  background-color: rgb(20, 20, 20) !important;
  color: #009dff;
}

.accordion-card {
  border: none !important;
}

.accordion-body-card {
  padding: 0.75rem !important;
}

.accordion-body {
  border-top: 1px gray solid;
}

li {
  list-style: square;
}

.tech-block {
  display: block;
  margin: 5px;
  position: relative;
  transition: 200ms;
}

.tech-block:hover {
  transform: scale(1.2);
}