.section-container {
  padding: 100px 0px 100px;
}

.section-title {
  margin-bottom: 40px;
}

.col-container {
  text-align: center;
}

.fadeIn {
  animation: fadeIn-full 500ms ease-in-out normal forwards;
}

.button {
  margin: 0.5rem;
  border: 1px gray solid;
  transition: 200ms;
  background: white;
}

.button:hover {
  color: #009dff;
  background-color: rgb(20, 20, 20);
}

.peripheral-text {
  color: gray;
}

.subtitle {
  padding: 10px 0 10px;
}

.highlight-button {
  background-color: transparent;
  padding: 15px 40px;
  transition: 200ms;
  margin: 0.5rem;
}

.highlight-black {
  color: black;
  border: 1px gray solid;
}

.highlight-white {
  color: white;
  border: 1px white solid;
}

.highlight-white:hover {
  padding: 15px 60px;
  background-color: rgba(20, 20, 20, .3);
}

.highlight-black:hover {
  padding: 15px 60px;
  color: #009dff;
  background-color: black;
}

@-webkit-keyframes fadeIn-full {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn-full {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn-full {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn-full {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.opacity-zero {
  opacity: 0;
}