.hero-container {
  background: rgb(17, 23, 87);
  background: linear-gradient(
    180deg,
    rgba(17, 23, 87, 1) 13%,
    rgb(9, 98, 214) 56%,
    rgb(17, 169, 240) 100%
  );
}

.hero-title {
  font-size: 4rem;
  font-weight: 300 !important;
}

.hero-bg {
  height: 650px;
  background-image: url(../assets/cityscape.png);
  background-repeat: repeat;
  background-size: cover;
  animation: panno linear 1500s infinite;
}

.hero-text {
  text-align: center;
  color: white;
  position: absolute;
  padding: 480px 0 5% 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.hero-links {
  justify-content: center;
  padding: 20px;
}

.hero-button {
  background: rgba(39, 39, 39, 0.5);
  color: white;
  padding: 10px;
}

@keyframes panno {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 0;
  }
}

@-webkit-keyframes panno {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 0;
  }
}
@-moz-keyframes panno {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 0;
  }
}
@-o-keyframes panno {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 0;
  }
}

@media (max-width: 991px) {
  .hero-text {
    padding: 45% 5% 5% 5%;
  }
}