.contact-bg {
  background: rgb(20, 20, 20);
}

.contact-section-container {
  padding: 100px 0px 0px;
}
  
.contact-text {
  padding: 20px;
}

.lower-contact {
  margin-bottom: 60px;
  color: gray;
}

.contact-options {
  justify-content: center;
  padding: 2rem 0;
}

.contact-button {
  background: rgb(39, 39, 39);
  color: white;
  padding: 15px;
}

.contact-button:hover {
  background-color: #009dff;
  color: white;
  border: 1px #009dff solid;
}

.big-icon {
  font-size: 40px;
  padding-bottom: 40px;
}