.custom-nav {
    transition-property: background-color;
    transition-duration: 300ms;
    padding: 10px 20px !important;
    letter-spacing: 1px;
    font-size: large;
}

.brand {
    text-decoration: none;
}

.brand:hover > .brand-logo {
    transform: rotateZ(-135deg);
}

.scrolled {
    background: rgb(20, 20, 20);
}

.nav-link {
    color: white !important;
    transition: 100ms;
}

.nav-link:hover {
    color: #52cbff !important;
}

.located {
    color: #009dff !important
}

.brand-logo {
    margin-top: -5px;
    margin-right: 15px;
    transition: 200ms;
}

@media (max-width: 991px) {
    .custom-nav {
      background-color: rgb(20, 20, 20);
    }
  }