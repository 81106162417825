.skill-block {
  display: block;
  margin: 25px;
  position: relative;
  transition: 200ms;
}

.skill-displays {
  align-items: flex-start;
  justify-content: center;
}

.skill-sec {
  margin-top: 20px;
  padding: 2px 0;
  background-color: rgb(233, 233, 233);
}

.skill-list {
  justify-content: center;
  padding: 1rem;
}

.ind-skill-row {
  justify-content: space-evenly;
  padding: 20px;
}

.skill-subtitle {
    margin: 30px auto;
    color: #009dff;
}

.skill-name {
  display: flex;
  margin: 5px auto;
  font-size: 1rem;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: smaller;
}

.display-icon {
  margin: auto 10px;
}

@media only screen and (min-width: 1222px) {
  .skill-displays {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
}
