.contact-form {
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}

.form-field {
  position: relative;
}

.spinner {
  color: black;
}

.contact-button:hover > .spinner {
  color: white;
}

.custom-input {
  width: 100%;
  padding: 10px;
  font-size: large;
}

.custom-input:focus {
  outline: none;
}