.gradient-bg {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 50%,
    #0d0d0d 50%
  );
}

.clipped-gradient-bg {
    clip-path: polygon(50% 6%, 100% 0, 100% 100%, 0 100%, 0 0);
    background-color: white;
}

.bio {
  padding-top: 200px;
}

.bio-image {
  padding: 0 0 50px;
}
