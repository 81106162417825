.project-wrapper {
  /* padding: 20px 0; */
}

.project {
  position: relative;
  margin: 20px auto;
  transform: rotateY(90deg);
}

.project-img {
  width: 100%;
}

.project:hover > .project-overlay {
  opacity: 1;
  cursor: pointer;
}

.project-overlay {
  position: absolute;
  background-color: rgba(26, 30, 31, 0.85);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 200ms;
}

.tech-list {
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-name {
  color: white;
  height: auto;
}

.project-overlay-info {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.project-description {
  margin: 20px auto;
  width: 100%;
}

.project-button {
  padding: 10px 25px 10px;
  border: 1px gray solid;
}

/* .project-button:hover {
  padding: 5px 45px 5px;
} */

.disabled-button {
  margin: 0.5rem;
  border: 1px gray solid;
  transition: 200ms;
  background: gray;
  padding: 10px 25px 10px;
}

.modal-edge {
  padding: .5rem .5rem !important;
  justify-content: center !important;
}

.modal-title {
  margin: 0 auto;
}

.modal-body {
  text-align: center;
}

.x-button {
  position: absolute;
  left: 85%;
}

.close-button {
  padding: .2rem 1.5rem;
}